body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

p {
  margin: 0;
}

a, button {
  outline: none;
}

a:active,
a:focus,
button:active,
button:focus {
  outline: none;
}